// Generated by Framer (ff86393)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {mbJB_BPwk: {hover: true}};

const serializationHash = "framer-sE2q8"

const variantClassNames = {mbJB_BPwk: "framer-v-1ehmbyi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, httpsAppsAppleComUsAppHealthFitnessMetricId6630364978, id, padding, text, width, ...props}) => { return {...props, fObzr7CSv: padding ?? props.fObzr7CSv ?? "16px 24px 16px 24px", N63XhVcp9: httpsAppsAppleComUsAppHealthFitnessMetricId6630364978 ?? props.N63XhVcp9, tPt3lgaOJ: text ?? props.tPt3lgaOJ ?? "Download App"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;httpsAppsAppleComUsAppHealthFitnessMetricId6630364978?: string;text?: string;padding?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, N63XhVcp9, tPt3lgaOJ, fObzr7CSv, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "mbJB_BPwk", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={N63XhVcp9} nodeId={"mbJB_BPwk"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1ehmbyi", className, classNames)} framer-1aq9njk`} data-framer-name={"Primary Button"} layoutDependency={layoutDependency} layoutId={"mbJB_BPwk"} ref={ref ?? ref1} style={{"--4qw4zx": numberToPixelString(fObzr7CSv), backgroundColor: "var(--token-517feb77-1955-4312-869e-627a1c7f4cb2, rgb(255, 255, 255))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} variants={{"mbJB_BPwk-hover": {backgroundColor: "var(--token-e3f544b7-ec9a-4af0-877f-b147ebbf176c, rgb(228, 228, 230))"}}} {...addPropertyOverrides({"mbJB_BPwk-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTYwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-f96f6f53-0e30-45be-8d4f-78a6a58bc123, rgb(0, 0, 0)))"}}>Download App</motion.p></React.Fragment>} className={"framer-zo7cxc"} data-framer-name={"Text"} fonts={["GF;Instrument Sans-600"]} layoutDependency={layoutDependency} layoutId={"QxpCeT7jR"} style={{"--extracted-r6o4lv": "var(--token-f96f6f53-0e30-45be-8d4f-78a6a58bc123, rgb(0, 0, 0))", "--framer-paragraph-spacing": "0px"}} text={tPt3lgaOJ} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sE2q8.framer-1aq9njk, .framer-sE2q8 .framer-1aq9njk { display: block; }", ".framer-sE2q8.framer-1ehmbyi { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: visible; padding: var(--4qw4zx); position: relative; text-decoration: none; width: min-content; }", ".framer-sE2q8 .framer-zo7cxc { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sE2q8.framer-1ehmbyi { gap: 0px; } .framer-sE2q8.framer-1ehmbyi > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-sE2q8.framer-1ehmbyi > :first-child { margin-left: 0px; } .framer-sE2q8.framer-1ehmbyi > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 59
 * @framerIntrinsicWidth 173
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"jzRsU2ZHg":{"layout":["auto","auto"]}}}
 * @framerVariables {"N63XhVcp9":"httpsAppsAppleComUsAppHealthFitnessMetricId6630364978","tPt3lgaOJ":"text","fObzr7CSv":"padding"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHkHfAuVqK: React.ComponentType<Props> = withCSS(Component, css, "framer-sE2q8") as typeof Component;
export default FramerHkHfAuVqK;

FramerHkHfAuVqK.displayName = "Buttons/ Primary Button";

FramerHkHfAuVqK.defaultProps = {height: 59, width: 173};

addPropertyControls(FramerHkHfAuVqK, {N63XhVcp9: {description: "", title: "https://apps.apple.com/us/app/health-fitness-metric/id6630364978", type: ControlType.Link}, tPt3lgaOJ: {defaultValue: "Download App", displayTextArea: false, title: "Text", type: ControlType.String}, fObzr7CSv: {defaultValue: "16px 24px 16px 24px", title: "Padding", type: ControlType.Padding}} as any)

addFonts(FramerHkHfAuVqK, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSQb_gfwmS0v3_7Y.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})